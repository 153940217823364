import React, { useContext } from "react"
import styled from "styled-components"
import SectionWrapper from "../components/section-wrapper/section-wrapper.component"
import { Container, keyframes } from "@mui/material"
import logo from "../assets/icons/a-logo.svg"
import CustomButton from "../components/custom-button/custom-button.component"
import { DrawerContext } from "../provider"
import text from "../assets/icons/text.svg"
import LetsTalkDrawer from "../components/lets-talk-drawer/lets-talk-drawer.component"
import SEO from "../components/seo/seo.component"

const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 100vh;
  width: 100vw;
  padding-top: 2rem;
`
const Wrapper = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LogoContainer = styled.div`
  position: absolute;
`
const AftershockLogo = styled(logo)`
  width: 150px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100px;
  }

  .cls-1 {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`
const AftershockText = styled(text)`
  width: 500px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 350px;
  }

  .cls-1 {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`
const AftershockTextInternal = styled(text)`
  width: 350px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 250px;
  }

  .cls-1 {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`
const ButtonContainer = styled.div`
  // width: 200px;
  // margin-left: auto;
  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   width: 160px;
  // }
  display: flex;
  justify-content: right;
  padding-right: 2rem;
`
const Button = styled(CustomButton)`
  background-color: rgb(151, 151, 151, 0.2) !important;
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    background-color: white !important;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
const rotate = keyframes`
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
`
const rotate1 = keyframes`
  from {
    -webkit-transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
  from {
    -moz-transform: rotate(-360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
  from {
    -ms-transform: rotate(-360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
  from {
    -o-transform: rotate(-360deg);
  }
  to {
    -o-transform: rotate(0);
  }
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0);
  }
`
const ContainerCircle = styled.div`
  position: absolute;

  svg {
    -webkit-animation-name: ${rotate};
    -moz-animation-name: ${rotate};
    -ms-animation-name: ${rotate};
    -o-animation-name: ${rotate};
    animation-name: ${rotate};
    -webkit-animation-duration: 25s;
    -moz-animation-duration: 25s;
    -ms-animation-duration: 25s;
    -o-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
  }
`
const ContainerCircleInternal = styled.div`
  svg {
    -webkit-animation-name: ${rotate1};
    -moz-animation-name: ${rotate1};
    -ms-animation-name: ${rotate1};
    -o-animation-name: ${rotate1};
    animation-name: ${rotate1};
    -webkit-animation-duration: 25s;
    -moz-animation-duration: 25s;
    -ms-animation-duration: 25s;
    -o-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
  }
`

const ComingSoon = () => {
  const { setIsFormDrawerOpen } = useContext(DrawerContext)
  return (
    <>
      <SEO
        data={{
          title: "Aftershock",
          metaRobotsNoindex: "noindex",
          metaRobotsNofollow: "nofollow",
        }}
      />
      <Section>
        <LetsTalkDrawer />
        <ButtonContainer>
          <Button onClick={() => setIsFormDrawerOpen(true)}>Contact Us</Button>
        </ButtonContainer>
        <Wrapper>
          <ContainerCircle>
            <AftershockText />
          </ContainerCircle>
          <ContainerCircleInternal>
            <AftershockTextInternal />
          </ContainerCircleInternal>
          <LogoContainer>
            <AftershockLogo />
          </LogoContainer>
        </Wrapper>
      </Section>
    </>
  )
}

export default ComingSoon
